.btn-cf {
    border-width: 0; // Custom border width
    // border-style: solid; // Custom border style
    // border-color: #01a6d9; // Custom border color
    &:hover {
        border-color: darken(#01a6d9, 10%); // Darken the border color on hover
    }
}
/* Apply viewport height at medium (md) breakpoint and up */
// <div class="vh-100-md">This div will be 100vh tall on medium devices and larger.</div>
@media (min-width: 768px) {
    .vh-md-100 {
      height: 100vh;
    }
    .pbd-10px {
        margin-bottom: 15px;
    }
    
}
.accordion-item:first-of-type {
    border-bottom: 20px solid #1a252a;
}
@media (max-width: 766px) {
    /* Vos styles spécifiquement pour extra small (xs) vont ici */
    .vh-sm-100 {
        height: calc(100vh - 3rem);
    }
    .bg-info {
        background-color: #008e81!important;
    }
    .mobile-bg {
        background-color: #13627F!important;
    }
}
.row > .col-auto {
    padding-right: 10px; /* Adds space between columns */
    padding-left: 10px;  /* Adds space between columns */
}
.mapboxgl-ctrl-bottom-left, .mapboxgl-ctrl-bottom-right {
    display: none;
}
.custom-cf-title {
    font-size: 1.5rem;
}
@media (max-width: 1246px) {
    .custom-cf-panel {
        flex: 0 0 auto;
        width: 31%;
    }
    .custom-cf-map {
        flex: 0 0 auto;
        width: 69%;
    }
    .custom-cf-title {
        font-size: 1.4rem;
    }
}
@media (max-width: 767px) {
    .custom-cf-panel {
        flex: 0 0 auto;
        width: 0;
    }
    .custom-cf-map {
        flex: 0 0 auto;
        width: 100%;
    }
    .custom-cf-title {
        font-size: 1.4rem;
    }
}