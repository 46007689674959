$body-bg: #1a252a;
$body-color: #FFFFFF;
// 21323a
// Override default breakpoint values
$grid-breakpoints: (
  xs: 0,
  sm: 576px,
  md: 768px, // Change this as needed
  lg: 992px, // Change this as needed
  xl: 1200px, // Change this as needed
  xxl: 1400px // You can add more breakpoints if needed
);

// Optionally, adjust the container max-widths for each breakpoint
$container-max-widths: (
  sm: 540px,
  md: 720px,
  lg: 960px,
  xl: 1140px,
  xxl: 1320px,
);
$grid-breakpoints: map-merge(
  $grid-breakpoints,
  (
    "xxxl": 2000px, // You can add more breakpoints if needed
  )
);

// Optionally, extend container max-widths for the new breakpoints
$container-max-widths: map-merge(
  $container-max-widths,
  (
    "xxxl": 1920px, // Matching container width for xxxl
  )
);

$grid-gutter-width: 0;

// 2. Override primary and secondary color variables
$primary: #0b3a49; // New primary color
$secondary: #14252C; // New secondary color

// 3. Optionally, adjust other variables based on the new colors
// $success: #28a745;
// $danger: #dc3545;
// $warning: #ffc107;
$info: #283C28;

$btn-border-radius: .5rem;

$btn-padding-y: .5rem; // Custom vertical padding
$btn-padding-x: .5rem; // Custom horizontal padding


$box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
$btn-close-color: white;
$form-check-input-bg: grey;

// Define your custom font sizes

$font-size-base: 1rem !default;
$h1-font-size: $font-size-base * 2.5 !default;
$h2-font-size: $font-size-base * 2 !default;
$h3-font-size: $font-size-base * 1.75 !default;
$h4-font-size: $font-size-base * 1.5 !default;
$h5-font-size: $font-size-base * 1.25 !default;
$h6-font-size: $font-size-base !default;
// scss-docs-end font-variables

// scss-docs-start font-sizes
$font-sizes: (
  1: $h1-font-size,
  2: $h2-font-size,
  3: $h3-font-size,
  4: $h4-font-size,
  5: $h5-font-size,
  6: $h6-font-size
) !default;

$custom-font-sizes: (
  7: 0.75rem, // Add your custom size here
);

// Merge your custom map with Bootstrap’s default map
$font-sizes: map-merge($font-sizes, $custom-font-sizes);

$modal-content-bg: #13627F;
$modal-content-border-radius: 8px;
$modal-header-border-width: 0;
$modal-inner-padding: 0;

$tooltip-bg: white;
$tooltip-padding-y: .3rem;
$tooltip-padding-x: .5rem;
$tooltip-max-width: 300px;